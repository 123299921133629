
import {Route, Routes} from 'react-router-dom'
import Navbar from './Pages/Navbar';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Footer from './Pages/Footer';
import Products from './Pages/Products/Products';

function App() {
  return (
   <>
    <Navbar/>
    <Routes>
      <Route exact path="/" element={<Home/>}></Route>
      <Route exact path="/products" element={<Products/>}></Route>
      <Route exact path="/about" element={<About/>}></Route>
      <Route exact path="/contact" element={<Contact/>}></Route>
    </Routes>
    <Footer/>
   </>
  );
}

export default App;
