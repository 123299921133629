import React from 'react'
import Banner from './Banner'
import { ThemeProvider, useTheme } from '@mui/material'
import AboutUs from './AboutUs';
import Services from './Services';
import Portfolio from './Portfolio';
import Features from './Features';
import Testimonial from './Testimonial';
import Cta from './Cta';

const Home = () => {

    const theme=useTheme();

  return (
    <ThemeProvider theme={theme}>
      
        <Banner/>
        <AboutUs/>
        <Services/>
        <Portfolio/>
        <Cta/>
        <Features/>
        <Testimonial/>
        
    </ThemeProvider>
  )
}

export default Home