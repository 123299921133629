import { Container, Grid2, List, ListItem, ListItemIcon, ListItemText, ThemeProvider, Typography, useTheme, Box } from '@mui/material'
import React from 'react'

//icons
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LanguageIcon from '@mui/icons-material/Language';
import FactoryRoundedIcon from '@mui/icons-material/FactoryRounded';
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded';
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded';
import AssuredWorkloadRoundedIcon from '@mui/icons-material/AssuredWorkloadRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import PatternRoundedIcon from '@mui/icons-material/PatternRounded';
import TonalityRoundedIcon from '@mui/icons-material/TonalityRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import InterpreterModeRoundedIcon from '@mui/icons-material/InterpreterModeRounded';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import TagRoundedIcon from '@mui/icons-material/TagRounded';
import InsertEmoticonRoundedIcon from '@mui/icons-material/InsertEmoticonRounded';
import BrandingWatermarkRoundedIcon from '@mui/icons-material/BrandingWatermarkRounded';
import FunctionsRoundedIcon from '@mui/icons-material/FunctionsRounded';
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import GTranslateRoundedIcon from '@mui/icons-material/GTranslateRounded';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import ApiRoundedIcon from '@mui/icons-material/ApiRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import AirplanemodeActiveRoundedIcon from '@mui/icons-material/AirplanemodeActiveRounded';
import TheatersRoundedIcon from '@mui/icons-material/TheatersRounded';
import FestivalRoundedIcon from '@mui/icons-material/FestivalRounded';
import DynamicFeedRoundedIcon from '@mui/icons-material/DynamicFeedRounded';
import SpeakerRoundedIcon from '@mui/icons-material/SpeakerRounded';
import Groups3RoundedIcon from '@mui/icons-material/Groups3Rounded';
import PodcastsRoundedIcon from '@mui/icons-material/PodcastsRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import BurstModeRoundedIcon from '@mui/icons-material/BurstModeRounded';
import FlareRoundedIcon from '@mui/icons-material/FlareRounded';
import LineStyleRoundedIcon from '@mui/icons-material/LineStyleRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import PixRoundedIcon from '@mui/icons-material/PixRounded';


// Image
import predictiveAnalysis from '../../assets/images/predictive_analysis.jpg'
import Banner from './Banner';

const Products = () => {
    
    const theme = useTheme()
    
    const items = [
        {
           left:<img src={predictiveAnalysis} width="80%" style={{border:`5px solid ${theme.palette.common.black}`,borderRadius:20}}/>,
           right:{
                heading:'AI That Anticipates Your Business Needs',
                catagory:'Predictive Analytics',
                content:'Leverage data to predict future trends, customer behaviors, and market opportunities with CastleSpy’s Predictive Analytics.',
                keyFeatures:[
                    {
                        icon:<InsightsRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Data-driven forecasting.'
                    },
                    {
                        icon:<PatternRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Pattern recognition for strategic planning.'
                    },
                    {
                        icon:<TonalityRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Scalable for all business sizes.'
                    }
                ],
                useCases:[
                    {
                        icon:<MonetizationOnRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Sales and revenue forecasting.'
                    },
                    {
                        icon:<Inventory2RoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Inventory and supply chain management.'
                    },
                    {
                        icon:<InterpreterModeRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Customer retention strategies.'
                    }
                ]
            }
        },
        {
           left:{
            heading:'Smart Conversational AI',
            catagory:'ChatBot',
            content:'Our AI-powered ChatBot delivers intelligent, human-like conversations, enhancing customer support and automating repetitive queries. Designed to adapt to your industry, it ensures seamless user interactions across platforms.',
            keyFeatures:[
                {
                    icon:<SupportAgentIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                    feature:'24/7 Customer Support.'
                },
                {
                    icon:<LanguageIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                    feature:'Multilingual Support.'
                },
                {
                    icon:<FactoryRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                    feature:'Customizable for various industries (e.g., e-commerce, healthcare, finance).'
                }
            ],
            useCases:[
                {
                    icon:<LocalGroceryStoreRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                    case:'E-commerce: Product inquiries and purchase assistance.'
                },
                {
                    icon:<HealthAndSafetyRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                    case:'Healthcare: Appointment scheduling and patient queries.'
                },
                {
                    icon:<AssuredWorkloadRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                    case:'Banking: Account-related FAQs.'
                }
            ]
           },
           right:<img src={predictiveAnalysis} width="100%" style={{border:`0px solid ${theme.palette.common.black}`,borderRadius:10}}/>
        },
        {
           left:'Image',
           right:{
                heading:'AI-Powered Sentiment Insights',
                catagory:'Sentiment Analysis',
                content:'Gain valuable insights into customer emotions with Sentiment Analysis. Understand feedback, monitor brand reputation, and improve decision-making.',
                keyFeatures:[
                    {
                        icon:<RecordVoiceOverRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Text and voice sentiment analysis.'
                    },
                    {
                        icon:<TagRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Real-time monitoring for social media and reviews.'
                    },
                    {
                        icon:<InsertEmoticonRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Customizable sentiment reports.'
                    }
                ],
                useCases:[
                    {
                        icon:<BrandingWatermarkRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Brand reputation management.'
                    },
                    {
                        icon:<FunctionsRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Customer service improvement.'
                    },
                    {
                        icon:<QrCode2RoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Product feedback analysis.'
                    }
                ]
           }
        },
        {
           left:{
                heading:'Effortless Language Translation',
                catagory:'Translation',
                content:"Break language barriers with CastleSpy's AI Translation service. Our solution provides accurate, context-aware translations across multiple languages, helping businesses expand globally.",
                keyFeatures:[
                    {
                        icon:<GTranslateRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Support for 100+ languages.'
                    },
                    {
                        icon:<TranslateRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Contextual and culturally sensitive translations.'
                    },
                    {
                        icon:<ApiRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'API integration for websites and mobile apps.'
                    }
                ],
                useCases:[
                    {
                        icon:<CategoryRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Global e-commerce: Product descriptions in multiple languages.'
                    },
                    {
                        icon:<AirplanemodeActiveRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Travel and Tourism: Multilingual guides and brochures.'
                    },
                    {
                        icon:<TheatersRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Media: Subtitling and dubbing assistance.'
                    }
                ]
           },
           right:'Image'
        },
        {
           left:'Image',
           right:{
                heading:'Accurate AI-Driven Transcription',
                catagory:'Transcription',
                content:'Transform audio into text with unparalleled accuracy using our AI Transcription service. Ideal for meetings, interviews, and content creation, this tool ensures precision and saves time.',
                keyFeatures:[
                    {
                        icon:<FestivalRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Real-time transcription for live events.'
                    },
                    {
                        icon:<DynamicFeedRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Supports multiple audio formats.'
                    },
                    {
                        icon:<SpeakerRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Speaker differentiation and timestamping.'
                    }
                ],
                useCases:[
                    {
                        icon:<Groups3RoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Corporate: Meeting notes and webinar transcripts.'
                    },
                    {
                        icon:<PodcastsRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Media: Podcast and video transcription.'
                    },
                    {
                        icon:<FormatListNumberedRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Education: Lecture notes for students.'
                    }
                ]
           }
        },
        {
            left:{
                heading:'Creative AI for Stunning Visuals',
                catagory:'Image Generation',
                content:"CastleSpy's Image Generation tool leverages advanced AI to create unique, high-quality visuals from simple descriptions. Perfect for marketing, design, and creative projects.",
                keyFeatures:[
                    {
                        icon:<BurstModeRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Text-to-Image generation.'
                    },
                    {
                        icon:<FlareRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'High-resolution outputs suitable for professional use.'
                    },
                    {
                        icon:<LineStyleRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        feature:'Style customization (artistic, realistic, abstract).'
                    }
                ],
                useCases:[
                    {
                        icon:<StarRateRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Marketing: Custom visuals for ads.'
                    },
                    {
                        icon:<ViewQuiltRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'Content Creation: Blog and social media graphics.'
                    },
                    {
                        icon:<PixRoundedIcon sx={{color:theme.palette.primary.main, fontSize:30}}/>,
                        case:'E-commerce: Unique product designs.'
                    }
                ]
            },
            right:'Image'
        },
       
    ]

  return (
    <ThemeProvider theme={theme}>
    
     
            <Banner/>
            
      

    </ThemeProvider>

  )
}

export default Products