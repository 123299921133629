import { ThemeProvider, Typography, useTheme, Box, TextField, Grid2 } from '@mui/material'
import React from 'react'
import MyButton from '../../MyComponents/MyButton'

const Testimonial = () => {

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{textAlign:"center",mb:20,mt:20,pl:4,pr:4}}>
        <Typography variant='h3'>
          Subscribe 
        </Typography>
        <Typography variant='p' component="p" sx={{fontStyle:"oblique", mt:5,mb:5}}>Subscribe today for getting our excited offer and newsletter.</Typography>
        
        <Grid2 container justifyContent="center" alignItems="center" spacing={2}>
          <Grid2 size={{xs:12, sm:6, md:6, lg:3}}>
            <TextField variant="outlined" placeholder='Email for getting Exciting Offer' fullWidth sx={{ textAlign: "right"}}></TextField>
          </Grid2>
          <Grid2 size={{xs:12, sm:"auto", md:"auto", lg:"auto"}} sx={{mt:{xs:3, sm:0}}}>
            <MyButton variant="contained" text="Subscribe" sx={{ textAlign: "left" }}></MyButton>
          </Grid2>
        </Grid2>
      </Box>
    </ThemeProvider>
  )
}

export default Testimonial