import { Container, Grid2, ThemeProvider, Typography, useTheme, TextField, Card, CardMedia, Box, Button, Checkbox, Dialog, DialogActions } from '@mui/material'

//icon
import ContactUs from '../../assets/images/contact_us.jpeg'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Backdrop from '@mui/material/Backdrop';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useState} from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';

// getting server url from .env
import CheckCircleIcon from '@mui/icons-material/CheckCircle';




const Contact = () => {

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const theme = useTheme()
  const API_URL = process.env.REACT_APP_API_URL
  const API_MODE = process.env.REACT_APP_ENV

  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidMessage, setIsValidMessage] = useState(true);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /\+?\d[\d -]{8,12}\d/;

  const [formData, setFormData] = useState({
    first_name:"",
    last_name:"",
    email:"",
    phone_number:"",
    message:""
  })

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationObj = {
        first_name:true,
        last_name: true,
        email: true,
        phone_number: true,
        message: true
    }
    if(formData.first_name.trim()===""){
        validationObj.first_name = false
    }
    if(formData.last_name.trim()===""){
        validationObj.last_name=false
    }
    if(formData.email.trim()==="" || !emailRegex.test(formData.email)){
        validationObj.email = false
    }
    if(formData.phone_number.trim()==="" || !phoneRegex.test(formData.phone_number)){
        validationObj.phone_number=false
    }
    if(formData.message.trim()===""){
        validationObj.message=false
    }
    if(Object.values(validationObj).indexOf(false)=== -1){
        setOpenBackdrop(true)
        try {
            const response = await fetch("https://castlespy.com/api/contact/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                setOpenBackdrop(false)
                setModalOpen(true)
            } else {
                setOpenBackdrop(false)
                console.error("Failed to send data:", response.statusText);
                alert("Failed to send data!");
            }
        } catch (error) {
            console.error("Error:", error);
            alert("An error occurred while sending data!");
        }
    }else{
        setIsValidFirstName(validationObj.first_name)
        setIsValidLastName(validationObj.last_name)
        setIsValidEmail(validationObj.email)
        setIsValidPhoneNumber(validationObj.phone_number)
        setIsValidMessage(validationObj.message)
    }
    

  }

  const firstNameChange = (event)=>{
    setFormData({
        ...formData,
        first_name:event.target.value
    })
    setIsValidFirstName(true)
  }
  const lastNameChange = (event)=>{
    setFormData({
        ...formData,
        last_name:event.target.value
    })
    setIsValidLastName(true)
  }
  const emailChange = (event) =>{
    setFormData({
        ...formData,
        email:event.target.value
    })
    setIsValidEmail(true)
  }
  const phoneNumberChange = (event)=>{
    setFormData({
        ...formData,
        phone_number:event.target.value
    })
    setIsValidPhoneNumber(true)
  }
  const messageChange = (event)=>{
    setFormData({
        ...formData,
        message:event.target.value
    })
    setIsValidMessage(true)
  }

  const handleOk = (e) => {
    setModalOpen(false)
    formData.first_name = ""
    formData.last_name = ""
    formData.email = ""
    formData.phone_number = ""
    formData.message = ""
  }

  return (
    <ThemeProvider theme={theme}>
        {/* Backdrop */}
        <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={openBackdrop}
            // onClick={handleClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

        {/* Modal */}
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '90%', maxHeight: 635 } }}
            maxWidth="xs"
            open={modalOpen}
        >
            <DialogTitle>
                <Box display="flex">
                    <CheckCircleIcon sx={{color:theme.palette.primary.main,mr:2,fontSize:25}}/>
                    <Typography variant='h5'component="h5" fontWeight={700} color={theme.palette.primary.main}>
                        Success ! Massage Sent
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant='p' component="p">
                    Your record is successfully submitted and we will contact you soon. You can also contact on following
                </Typography>
                <Typography variant='p' component="p" sx={{mt:4}}>Email :-</Typography>
                <Typography variant='p' component="p" fontSize={25} color={theme.palette.secondary.dark}>info@castlespy.com</Typography>
                <Typography variant='p' component="p" sx={{mt:2}}>Phone :-</Typography>
                <Typography variant='p' component="p" fontSize={25} color={theme.palette.secondary.dark}>+91 6232-598-017</Typography>
                
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleOk} size="large">Ok</Button>
            </DialogActions>
        </Dialog>




        <Container sx={{textAlign:"center",mt:10,mb:10}}>
          <Typography variant='h3' sx={{mb:4}}>Contact Us</Typography>
          <Typography variant='p'>We are comprehensive service agency with specialists prepared to assist. We will contact you within 24 hours.</Typography>
        </Container>
        <Container maxWidth="lg" sx={{mb:20}}>
          <Grid2 size={{xs:12,sm:12, md:6, lg:6}}>
            <Container maxWidth="lg" sx={{mb:10 }}>
          
                <Grid2 container>
                    <Grid2 size={{xs:12, sm:12, md:6, lg:6}} sx={{p:4}}>
                        <Typography 
                            variant="h3" 
                            component="h1" 
                            align="left" 
                            gutterBottom
                            sx={{ mb: 4, fontWeight: 'bold',letterSpacing:2 }}
                        >
                            CONTACTS
                        </Typography>

                        <Card sx={{ maxWidth: 200,mt:5 }} elevation={0}>
                            {/* <CardActionArea> */}
                                <CardMedia
                                component="img"
                                height="200"
                                width="200"
                                image={ContactUs}
                                alt="green iguana"
                                sx={{borderRadius:100}}
                            
                                />
                              
                            {/* </CardActionArea> */}
                        </Card>

                        <Typography variant='p' component="p" sx={{mt:5}}>Use our contact form for all information request or contact us directly using the contact information below.</Typography>
                        
                        <Typography variant='p' component="p" sx={{mt:5}}>Feel Free to get in touch with us via email or phone.</Typography>
                    
                    </Grid2>
                    <Grid2 size={{xs:12, sm:12, md:6, lg:6}} sx={{p:2}}>
                        <Container maxWidth="sm">
                            <Typography variant='p' component="p" sx={{mb:4}}>We will connect you as soon as possible, Thank You</Typography>
                            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} >
                                <Grid2 container spacing={3}>
                                        <Grid2 size={{xs:6}}>
                                            <TextField
                                                required
                                                label="First Name"
                                                fullWidth
                                                onChange={firstNameChange}
                                                value={formData.first_name}
                                                name="first_name"
                                                error={!isValidFirstName}
                                                >
                                            </TextField>
                                        </Grid2>
                                        <Grid2 size={{xs:6}}>
                                            <TextField
                                                required
                                                label="Last Name"
                                                fullWidth
                                                onChange={lastNameChange}
                                                value={formData.last_name}
                                                name="last_name"
                                                error={!isValidLastName}
                                                    >

                                                </TextField>
                                        </Grid2>

                                        <Grid2 size={{xs:12}}>
                                            <TextField
                                                required
                                                label="Email"
                                                fullWidth
                                                inputMode='email'
                                                onChange={emailChange}
                                                value={formData.email}
                                                name="email"
                                                error={!isValidEmail}
                                            >

                                            </TextField>
                                        </Grid2>
                                        <Grid2 size={{xs:12}}>
                                            <TextField
                                                required
                                                label="Phone Number"
                                                fullWidth
                                                onChange={phoneNumberChange}
                                                value={formData.phone_number}
                                                name="phone_number"
                                                error={!isValidPhoneNumber}
                                            >

                                            </TextField>
                                        </Grid2>  
                                        <Grid2 size={{xs:12}}>
                                          <TextField 
                                            multiline 
                                            maxRows={4} 
                                            fullWidth 
                                            label='Message' 
                                            rows={4}
                                            onChange={messageChange}
                                            value={formData.message}
                                            name="message"
                                            error={!isValidMessage}
                                            ></TextField>

                                        </Grid2>
                                        <Grid2 size={{xs:6,sm:6,md:6,lg:6}}>
                                          <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked/>} label="Research Planning"/>
                                            <FormControlLabel control={<Checkbox />} label="Buisness Consultancy"/>
                                            <FormControlLabel control={<Checkbox defaultChecked size="small" />} label="Web Dev"/>
                                          </FormGroup>
                                        </Grid2> 
                                        <Grid2 size={{xs:6,sm:6,md:6,lg:6}}>
                                          <FormGroup>
                                              <FormControlLabel control={<Checkbox />} label="Social Engineering"/>
                                              <FormControlLabel control={<Checkbox defaultChecked/>} label="AI/ML Dev"/>
                                              <FormControlLabel control={<Checkbox size="small" />} label="Dev Ops"/>
                                            </FormGroup>
                                        </Grid2>
                                        <Grid2 item size={{xs:12}}>
                                            <Button variant='contained' type="submit" sx={{textTransform:"none",}} >Send Message</Button>
                                        </Grid2>
                                        
                                </Grid2>
                            </Box>
                        </Container>
                        
                    </Grid2>
                </Grid2>

          
            </Container>
          </Grid2>
        </Container>
    </ThemeProvider>
  )
}

export default Contact