import { ThemeProvider, Typography, useTheme, Box, Grid2, Container } from '@mui/material'
import React from 'react'

const Features = () => {
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{textAlign:"center", alignItems:"center", backgroundColor:theme.palette.secondary.main, mb:10,pt:10,pb:10, boxShadow:10}}>
      <Container>
        <Grid2 container>
        <Grid2 size={{xs:12, sm:6, md:3, lg:3}} sx={{p:7}}>
          <Typography variant='h4'>89</Typography>
          <Typography variant='h6' sx={{mt:2}}>Project Delivered</Typography>

        </Grid2>
        <Grid2 size={{xs:12, sm:6, md:3, lg:3}} sx={{p:7}}>
          <Typography variant='h4'>4</Typography>
          <Typography variant='h6' sx={{mt:2}}>Project Runing</Typography>

        </Grid2>
        <Grid2 size={{xs:12, sm:6, md:3, lg:3}} sx={{p:7}}>
          <Typography variant='h4'>12</Typography>
          <Typography variant='h6' sx={{mt:2}}>AI Models in Production</Typography>

        </Grid2>
        <Grid2 size={{xs:12, sm:6, md:3, lg:3}} sx={{p:7}}>
          <Typography variant='h4'>20</Typography>
          <Typography variant='h6' sx={{mt:2}}>Happy Clients</Typography>

        </Grid2>
        </Grid2>
      </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Features