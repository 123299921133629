import React,{ useState } from 'react'
import { ThemeProvider, useTheme } from '@emotion/react'
import { Link } from 'react-router-dom'
import { AppBar, CssBaseline, Toolbar, Typography, Box, Container, Button, IconButton, List, ListItem, ListItemButton, ListItemText,Drawer} from '@mui/material'

// Icons and Images
import MenuIcon from '@mui/icons-material/Menu'
import Logo from '../assets/images/logo.png'

const drawerWidth = 400
const navItems = [
    {
        key:1,
        text:"Home",
        link:"/"
    },
    {
        key:2,
        text:'Products',
        link:'/products'
    },
    {
        key:3,
        text:"About",
        link:"/about"
    },
    {
        key:4,
        text:"Contact",
        link:"/contact"
    },   
]


const Navbar = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme()

    const handleDrawerToggle = ()=>{
        setMobileOpen((prevState)=> !prevState)
    }

    // Defining Drawer
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign:'center', display:"block"}}>

            <img src={Logo} alt="logo_castlespy" width="100%" sx={{p:4}}/>
            
            <List>
                {navItems.map((item)=>(
                    <ListItem key={item.key}>
                        <ListItemButton sx={{textAlign:"center"}} to={item.link} component={Link}>
                            <ListItemText primary={item.text}></ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    )

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{mt:3}}>
        <CssBaseline/>
        <AppBar position="relative" sx={{backgroundColor:theme.palette.background.paper}} elevation={0}>
            <Container maxWidth="lg">
                <Toolbar>
                    <IconButton 
                        size="large"
                        color="#000" 
                        aria-label='open drawer' 
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        edge="start"
                        onClick={handleDrawerToggle} 
                        sx={{ display:{sm:"none"},color:theme.palette.common.black}}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography 
                        variant='h5' 
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mt:1,
                            flexGrow:1,
                            transition:"0.5s all",
                            
                        }}
                        >
                        <img src={Logo} alt="logo" height="60px" width="180px"/>
                    </Typography>
                    <Box sx={{ display:{xs:'none',sm:'block'}}}>
                        {navItems.map((item)=>(
                            <Button 
                                key={item.key} 
                                component={Link}
                                to={item.link}
                                sx={{
                                    textTransform:"none",
                                    fontWeight:"bold",
                                    color:theme.palette.common.black
                                }}>
                                {item.text}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

        <Box component="nav">
            <Drawer 
                variant='temporary' 
                open={mobileOpen} 
                onClose={handleDrawerToggle} 
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: {xs:'block', sm:'none'},
                    '& .MuiDrawer-paper': {boxSizing:'border-box', width:drawerWidth}
                }}>
                    {drawer}
            </Drawer>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Navbar