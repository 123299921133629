import { ThemeProvider, useTheme,Container, Typography, Box, Grid2 } from '@mui/material'
import React from 'react'


const item = [
  {
    heading:'AI-Powered Customer Insights for Retail',
    text:'A mid-sized retail chain faced challenges in understanding customer behavior and predicting sales trends, which often led to stock-outs and missed revenue opportunities. CastleSpy implemented an AI-driven predictive analytics solution by leveraging historical sales data and customer demographics. Through machine learning models, the system accurately forecasted demand and identified purchase patterns. As a result, the client saw a 15% increase in sales due to better inventory management, a 30% reduction in stock-outs, and actionable insights into customer preferences that informed their marketing strategies.',
    by:'A mid-sized retail chain'
  },
  {
    heading:'End-to-End Business Solution for a Logistics Company',
    text:'A growing logistics provider struggled with inefficiencies in shipment tracking, resource allocation, and invoice processing. These challenges resulted in delayed deliveries and customer dissatisfaction. CastleSpy designed and implemented a custom ERP system tailored to the logistics industry. The solution featured real-time tracking via a web dashboard, automated resource allocation using optimization algorithms, and integrated invoicing with built-in error detection. After implementation, the logistics provider reduced delivery times by 20%, cut operational costs by 18%, and significantly improved customer satisfaction ratings by 30%.',
    by:'A growing logistics provider'
  },
  {
    heading:'Web and Mobile App Development for an Educational Platform',
    text:'An online learning startup required a seamless platform for students to access courses across web and mobile devices. Their existing system was outdated and faced performance issues. CastleSpy built a cross-platform solution using React for web and React Native for mobile. The platform featured interactive course modules with video and quiz support, a gamified learning experience with badges and leaderboards, and real-time progress tracking for both students and teachers. The new system increased user engagement by 40%, reduced app load times by 50%, and scaled efficiently to handle 10,000 concurrent users without performance issues.',
    by:'An online learning startup'
  },
  {
    heading:'AI Chatbot Integration for Customer Support',
    text:'A financial services company’s customer support team was overwhelmed with repetitive inquiries, leading to long response times and frustrated customers. CastleSpy deployed an AI-powered chatbot trained on the company’s FAQs and support data. The chatbot effectively handled common queries such as account balance checks and loan application statuses, while routing complex issues to human agents. The solution resolved 70% of queries without human intervention, reduced response times from 10 minutes to under 30 seconds, and saved the company 35% annually on customer support costs.',
    by:'A financial services company'
  }
]


const Portfolio = () => {

  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{mt:10,mb:5}}>
        <Container maxWidth="xlg" sx={{backgroundColor:theme.palette.grey[200],pt:15,pb:10}}>
            <Typography variant='h3' sx={{textAlign:"center"}}>Case Studies</Typography>

            <Container maxWidth="lg" sx={{mt:6}}>
              <Grid2 container>
                {item.map((item, key)=>
                  <Grid2 size={{xs:12, sm:12, md:6, lg:6}} sx={{p:{xs:5,sm:10}}} key={key}>
                    <Typography variant='h6' sx={{fontSize:{xs:17,sm:20}}}>{item.heading}</Typography>
                    <Typography variant='p' component="p" sx={{fontSize:13,mt:3}}>{item.text}</Typography>
                    <Typography variant='p' component="p" sx={{mt:3,fontStyle:"italic"}}>"{item.by}"</Typography>
                  </Grid2>
                )}
              </Grid2>
            </Container>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Portfolio