import { ThemeProvider, Typography, useTheme, Container, Grid2,Box, Button } from '@mui/material'
import React from 'react'

// icons
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Person4RoundedIcon from '@mui/icons-material/Person4Rounded';
import Diversity2RoundedIcon from '@mui/icons-material/Diversity2Rounded';
import GroupWorkRoundedIcon from '@mui/icons-material/GroupWorkRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import MyButton from '../../MyComponents/MyButton';


import { Link } from 'react-router-dom'

const CoreValues = () => {
    const theme = useTheme()

    const items = [
        {
            icon:<EmojiEventsIcon sx={{fontSize:60}}/>,
            heading:'Customer First',
            paragraph:'Your satisfaction and trust are our highest priorities. We are committed to delivering exceptional service with a focus on your unique needs. Through personalized support, we ensure that your goals are met with excellence. Our mission is to build lasting relationships that foster your long-term success.',
            link:'/'
        },
        {
            icon:<Person4RoundedIcon sx={{fontSize:60}}/>,
            heading:'Personal Accountability',
            paragraph:'At CastleSpy, we take full ownership of your needs, delivering tailored solutions with precision. Our commitment to personal accountability ensures that your goals are met with excellence. We’re not just service providers, but trusted partners dedicated to your success every step of the way.',
            link:'/'
        },
        {
            icon:<Diversity2RoundedIcon sx={{fontSize:60}}/>,
            heading:'Commitment to Excellence',
            paragraph:'At CastleSpy, we are committed to delivering excellence in every solution we provide. Our focus on quality and precision ensures that your needs are met with the highest standards. We go above and beyond to exceed expectations and create lasting value for your business.',
            link:'/'
        },
        {
            icon:<GroupWorkRoundedIcon sx={{fontSize:60}}/>,
            heading:'Team Impowerment',
            paragraph:'At CastleSpy, we believe in empowering our team to drive innovation and deliver outstanding results. By fostering a culture of collaboration and trust, we enable each member to reach their full potential. Together, we create solutions that make a meaningful impact for our clients and their success.',
            link:'/'
        },
        {
            icon:<TrendingUpRoundedIcon sx={{fontSize:60}}/>,
            heading:'Continious Growth',
            paragraph:"We are dedicated to the continuous growth and development of our team. By providing opportunities for learning, training, and mentorship, we ensure that our experts stay ahead of industry trends and technologies. This commitment to growth fuels our ability to deliver cutting-edge solutions and stay adaptable to our clients' evolving needs.",
            link:'/'
        },
        {
            icon:<AddTaskRoundedIcon sx={{fontSize:60}}/>,
            heading:'Client-Centric Approach',
            paragraph:'At CastleSpy, we place our clients at the heart of everything we do. Our empowered team collaborates closely with you to understand your unique challenges and goals. This client-first mindset drives us to craft personalized, effective solutions that create lasting impact and drive business success.',
            link:'/'
        }
    ]

  return (
    <ThemeProvider theme={theme}>
        <Container maxWidth="xlg" sx={{textAlign:"center",backgroundColor:theme.palette.primary.main,pt:5,pb:5,color:theme.palette.common.white}}>
            <Typography variant="h4">Our Core Values</Typography>
            <Typography variant='p' component="p" sx={{mt:3,letterSpacing:2,mb:5}}>We break down barriers so teams can focus on what matters, <br/>working together to create products their customer love. </Typography>
        </Container>
        <Container sx={{mb:10}}>
            <Grid2 container sx={{mt:15}}>
                {items.map((item, key)=><>
                    <Grid2 size={{xs:12, sm:6, md:6, lg:6}} sx={{p:5}} key={key}>
                        <Box sx={{mb:5,p:5,border:0,borderRadius:5, backgroundColor:theme.palette.info.light,transition:"0.5s","&:hover":{boxShadow:20,transition:"0.5s all"}}} key={key}>
                            {item.icon}

                            <Typography variant='h5' sx={{mt:2,mb:2}}>{item.heading}</Typography>
                            <Typography variant='p' component="p" sx={{fontSize:13,mb:2}}>{item.paragraph}</Typography>
                            <MyButton text="Learn More" ></MyButton>
                        </Box>
                    </Grid2>
                </>)}
            </Grid2>
        </Container>
        <Container sx={{textAlign:"center",mb:10}}>
            <Typography variant='h4' sx={{mb:5}}>Need Contact ?</Typography>
            <a href="/contact">
                <MyButton variant="contained" text="Contact Us" ></MyButton>
            </a>
        </Container>
    </ThemeProvider>
  )
}

export default CoreValues