import React from 'react'
import Banner from './Banner'
import CoreValues from './CoreValues'

const About = () => {
  return (
    <>
    <Banner/>
    <CoreValues/>
    </>
  )
}

export default About