import { createTheme, responsiveFontSizes } from "@mui/material";

const lightTheme = createTheme({
    palette:{
        mode:"light",
        common:{
            black:"#1A1819",
            white:"#FFFFFF"
        },
        primary:{
            // Shades of Green
            main:"#2c877e",
            light:"#52c7bb",
            dark:"#123733",
        },
        secondary:{
            // shades of yellow
            main:"#c7ae6a",
            light:"#c0a459",
            dark:" #a68b3f"
        },
        info:{
            // Grey Shades
            main:"#C5C6C7",
            light:"#E1E1E1",
            dark:"#777B7E"
        },
        background:{
            paper:"#fff"
        }
    },
    typography:{
        fontFamily:"montserrat"
    }
})

export const responsiveLightTheme = responsiveFontSizes(lightTheme)