import { ThemeProvider, useTheme, Container, Grid2, Typography ,Button, Box, IconButton } from '@mui/material'
import React from 'react'

// image
import Logo from '../assets/images/logo_white.png'

// Icons
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';




const Footer = () => {
  
  const theme=useTheme()

  const footerItems = [
    {
      id:1,
      title:"Social",
      content:[
        {
          icon:<GoogleIcon sx={{color:theme.palette.common.white,"&:hover":{color:theme.palette.secondary.main}, fontSize:40,mr:3,mb:3}} />,
          link:'/'
        },
        {
          icon:<LinkedInIcon sx={{color:theme.palette.common.white, "&:hover":{color:theme.palette.secondary.main},fontSize:40,mr:3,mb:3}} />,
          link:'https://www.linkedin.com/company/castlespy/'
        },
        {
          icon:<InstagramIcon sx={{color:theme.palette.common.white, "&:hover":{color:theme.palette.secondary.main},fontSize:40,mr:3,mb:3}}/>,
          link:'https://www.instagram.com/castlespy/'
        },
        {
          icon:<FacebookIcon sx={{color:theme.palette.common.white,"&:hover":{color:theme.palette.secondary.main}, fontSize:40,mr:3,mb:3}}/>,
          link:'https://www.facebook.com/profile.php?id=61569593662078'
        },
        {
          icon:<XIcon sx={{color:theme.palette.common.white,"&:hover":{color:theme.palette.secondary.main}, fontSize:40,mr:3,mb:3}}/>,
          link:'https://x.com/CastleSpyy'
        },
        {
          icon:<YouTubeIcon sx={{color:theme.palette.common.white,"&:hover":{color:theme.palette.secondary.main}, fontSize:40,mr:3,mb:3}}/>,
          link:'https://www.youtube.com/@castlespy'
        },
        {
          icon:<img src={Logo} alt="CastleSpy Logo" width="180" />,
          link:'/'
        },
      ]
    },
    {
      id:2,
      title:'Explore Us',
      content:[
        {
          id:2.1,
          title:"Product",
          link:'/products'
        },
        {
          id:2.2,
          title:'Services',
          link:'/'
        },
        {
          id: 2.3,
          title: 'AI & ML Solutions',
          component: '/'
      },
      {
          id: 2.4,
          title: 'Cyber Security',
          component: '/'
      },
      {
          id: 2.5,
          title: 'Cloud Computing',
          component: '/'
      },
      {
          id: 2.6,
          title: 'Blockchain Technology',
          component: '/'
      },
      {
          id: 2.7,
          title: 'Generative AI Solutions',
          component: '/'
      },
      {
          id: 2.8,
          title: 'Custom Software Development',
          component: '/'
      },
      {
          id: 2.9,
          title: 'Training & Workshops',
          component: '/'
      },
      ]
    },
    {
      id:3,
      title:'About Us',
      content:[
            {
              id: 3.1,
              title: 'About Us',
              link: '/about'
          }, 
          {
            id: 3.1,
            title: 'Terms and Conditions',
            link: '/terms-and-conditions'
        },
        {
            id: 3.2,
            title: 'Privacy Policy',
            link: '/privacy-policy'
        },
        {
            id: 3.3,
            title: 'Help Center',
            link: '/help'
        },
        {
            id: 3.4,
            title: 'Contact Us',
            link: '/contact'
        },
      ]
    },
    {
      id: 4,
      title: 'Our Expertise',
      content: [
          {
              id: 4.1,
              title: 'AI & Machine Learning Solutions',
              component: '/ai-ml'
          },
          
          {
              id: 4.3,
              title: 'Custom Software Development',
              component: '/software-development'
          },
          {
              id: 4.4,
              title: 'Cloud Computing Solutions',
              component: '/cloud-computing'
          },
          
          {
              id: 4.5,
              title: 'Data Analytics & Insights',
              component: '/data-analytics'
          },
          {
              id: 4.6,
              title: 'Natural Language Processing Services',
              component: '/nlp'
          },
          {
              id: 4.7,
              title: 'Computer Vision Applications',
              component: '/computer-vision'
          },
         
          {
              id: 4.8,
              title: 'DevOps & CI/CD Implementation',
              component: '/devops'
          },
          {
              id: 4.9,
              title: 'Enterprise IT Consulting',
              component: '/it-consulting'
          }
      ]
    },
  ]
  
  
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xlg" sx={{backgroundColor:theme.palette.common.black, color:theme.palette.common.white, pt:15, pb:10}}>
        <Container>
          <Grid2 container>
            {footerItems.map((item, key1)=>
              <Grid2 size={{xs:6, sm:6, md:3, lg:3}} key={key1+890} sx={{mt:{xs:10,sm:10,md:0}}}>
                <Typography variant='h6' sx={{mb:3}}>{item.title}</Typography>
                {item.content.map((item2, key)=><>
                  {item.id===1?
                  <>
                    <IconButton href={item2.link} target="_blank" key={key+490}>
                      {item2.icon}
                    </IconButton>
                  </>
                  :<Box key={key+690}>
                      <Button 
                        variant="none" 
                        sx={{fontSize:12,textTransform:"none",justifyContent:"flex-start",textAlign:"left", "&:hover":{backgroundColor:theme.palette.primary.main}}}
                        component={Link}
                        to={item2.link}
                        >
                          {item2.title}
                      </Button>
                    </Box>}
                
                </>)}
              </Grid2>
            )}
          </Grid2>
          <Typography variant='p' component="p" sx={{textAlign:"center",mt:5,fontSize:12}}>copyright &copy; CastleSpy.com</Typography>
        </Container>
      </Container>
    </ThemeProvider>

  )
}

export default Footer