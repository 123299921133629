import { ThemeProvider, Typography, useTheme, Box, Container, Grid2 } from '@mui/material'
import MyButton from '../../MyComponents/MyButton'
import React from 'react'
import GreenLogo from '../../assets/images/transparent_green.png'

const Banner = () => {

    const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
        <Container>
            <Box textAlign="center" sx={{mt:{xs:5,sm:15}}}>
                <img src={GreenLogo} alt="Green Logo" width={200} height={200}/>
                
                <Typography variant='h3' sx={{mt:3}}>Empowering Your Business with Cutting-Edge IT Solutions.</Typography>
                <Typography variant='p' component="p" sx={{mt:3,mb:5}}>CastleSpy specializes in AI-driven insights, robust applications, and seamless business transformations.</Typography>

                <Grid2 container>
                    <Grid2 size={{xs:12, sm:2, md:2, lg:3}}></Grid2>
                    <Grid2 size={{xs:12, sm:4,md:4,lg:3}} sx={{p:1}}>
                        <a href="/products">
                            <MyButton variant='contained' text="Discover Our Solutions"/>
                        </a>
                    </Grid2>
                    <Grid2 size={{xs:12, sm:4,md:4,lg:3}} sx={{p:1}}>
                        <a href="/contact">
                            <MyButton variant='outlined' text="Get a Free Consultation"/>
                        </a>
                    </Grid2>
                    <Grid2 size={{xs:12, sm:2, md:2, lg:3}} ></Grid2>
                </Grid2>
                
            
            </Box>
        </Container>
    </ThemeProvider>
  )
}

export default Banner