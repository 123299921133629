import { ThemeProvider, useTheme, Box, Container, Typography,Grid2 } from '@mui/material'
import MyButton from '../../MyComponents/MyButton'
import React from 'react'

const Cta = () => {
  const theme = useTheme()

  return (
    <ThemeProvider theme={theme}>
        <Box sx={{mt:15,mb:15}}>
          <Container sx={{textAlign:"center"}}>
            <Typography variant='h5' component="h5">Ready to Elevate Your Business with Technology?</Typography>
            <Typography variant='p' component="p" sx={{mt:5}}>Contact us today for a free consultation and see the difference!"</Typography>

            <Grid2 container sx={{mt:5}}>
                <Grid2 size={{xs:12, sm:2, md:2, lg:3}}></Grid2>
                <Grid2 size={{xs:12, sm:4,md:4,lg:3}} sx={{p:1}}>
                    <MyButton variant='contained' text="Get a Free Consultation"/>
                </Grid2>
                <Grid2 size={{xs:12, sm:4,md:4,lg:3}} sx={{p:1}}>
                    <MyButton variant='outlined' text="Discover Our Solutions"/>
                </Grid2>
                <Grid2 size={{xs:12, sm:2, md:2, lg:3}} ></Grid2>
            </Grid2>
          </Container>
        </Box>
    </ThemeProvider>
  )
}

export default Cta