import { useTheme } from '@emotion/react'
import { Container, ThemeProvider, Typography,Grid2,TextField,Box } from '@mui/material'
import MyButton from '../../MyComponents/MyButton'

import React from 'react'

const Banner = () => {
    const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
        <Container  maxWidth="lg" sx={{mt:15,mb:20}}>

            <Typography variant='h3' sx={{textAlign:"center",fontWeight:700, color:theme.palette.primary.dark}}>Transforming Ideas into Impact with Smart IT Solutions and Services.</Typography>
            <Typography variant='p' component="p" sx={{textAlign:'center',mt:3, letterSpacing:2, fontSize:20}}>Innovation. Excellence. Beyond.</Typography>
        
            <Grid2 container justifyContent="center" alignItems="center" spacing={2} sx={{mt:5}}>
                <Grid2 size={{xs:12, sm:6, md:6, lg:3}}>
                    <TextField variant="outlined" placeholder='Email for getting Exciting Offer' fullWidth sx={{ textAlign: "right"}}></TextField>
                </Grid2>
                <Grid2 size={{xs:12, sm:"auto", md:"auto", lg:"auto"}} sx={{mt:{xs:3, sm:0}}}>
                    <Box sx={{textAlign:"center"}}>
                        <MyButton variant="contained" text="Subscribe" sx={{ textAlign: "left" }}></MyButton>
                    </Box>
                </Grid2>
            </Grid2>
        </Container>
    </ThemeProvider>
  )
}

export default Banner