import React from 'react'
import { Container, Grid2, ThemeProvider, Typography, Link, List, ListItem, ListItemText } from '@mui/material'
import { useTheme } from '@emotion/react'

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BorderInnerIcon from '@mui/icons-material/BorderInner';
import Person4Icon from '@mui/icons-material/Person4';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

const links1 = [
    {
        text:'Our Mission',
        link:'/'
    },
    {
        text:'Case Studies',
        link:'/'
    },
    {
        text:'Careers',
        link:'/'
    }
]

const links2 = [
    {
        text:'Blog',
        link:'/'
    },
    {
        text:'Contact Us',
        link:'/'
    },
    {
        text:'FAQs',
        link:'/'
    }
]


const about = [
    {
        icon:<BorderInnerIcon sx={{fontSize:80}}/>,
        heading:'Innovation',
        text:'Continuously pushing the boundaries of technology.'
    },
    {
        icon:<Person4Icon sx={{fontSize:80}}/>,
        heading:'Client-Centricity',
        text:'Your success is our top priority.'
    },
    {
        icon:<IntegrationInstructionsIcon sx={{fontSize:80}}/>,
        heading:'Integrity',
        text:'We uphold transparency and honesty in every partnership.'
    },
    {
        icon:<CrisisAlertIcon sx={{fontSize:80}}/>,
        heading:'Excellence',
        text:'Delivering quality that speaks for itself.'
    }
]

const AboutUs = () => {
    const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
        <Container maxWidth="xlg" sx={{backgroundColor:theme.palette.primary.dark,mt:10}}>
            <Grid2 container sx={{p:{xs:5,sm:10,md:10,lg:15}, color:theme.palette.background.paper}}>
                <Grid2 size={{xs:12,sm:12,md:6,lg:6}}>
                    <Typography variant="h5" component="p">
                        Innovating Tomorrow with Technology Today
                    </Typography>
                    <Typography variant="h3" component="p" sx={{mt:2}}>
                        Driving Digital Transformation with <span sx={{color:theme.palette.secondary.main}}>AI</span> and IT Expertise
                    </Typography>
                    <Typography variant='p' component="p" sx={{mt:2}}>
                        CastleSpy is a cutting-edge IT services and solutions company dedicated to driving innovation and delivering impactful results. Our mission is to empower businesses with tailored technology solutions that transform ideas into reality, from AI-driven insights to robust web and mobile applications.
                    </Typography>

                    {/* Links Grid */}
                    <Grid2 container sx={{mt:3}}>
                        <Grid2 size={{xs:6,sm:6,md:6,lg:6}}>
                            <List>
                                {links1.map((item, key)=> 
                                    <ListItem sx={{p:0}} disablePadding>
                                        <ListItemText>
                                            <Link href={item.link} sx={{color:theme.palette.background.paper}}>
                                                {item.text}
                                                <OpenInNewIcon sx={{fontSize:15,ml:1}}/>
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                )}
                             </List>
                        </Grid2>
                        <Grid2 size={{xs:6,sm:6,md:6,lg:6}}>
                                {links2.map((item, key)=> 
                                    <ListItem sx={{p:0}}>
                                        <ListItemText>
                                            <Link href={item.link} sx={{color:theme.palette.background.paper}}>
                                                {item.text}
                                                <OpenInNewIcon sx={{fontSize:15,ml:1}}/>
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                )}
                        </Grid2>
                    </Grid2>

                </Grid2>
                <Grid2 size={{xs:12,sm:12,md:6,lg:6}} sx={{pl:{xs:0,md:15},pt:{xs:10,md:0}}}>
                    <Typography variant='h5' component="h5">About CastleSpy</Typography>
                    <Grid2 container sx={{mt:5}}>
                        {about.map((item, key)=>
                            <Grid2 size={{xs:6,sm:6,md:6,lg:6}} sx={{mb:10}}>
                                <Typography variant='p'>{item.icon}</Typography>
                                <Typography variant='h5' component="h5" sx={{color:theme.palette.secondary.light}}>{item.heading}</Typography>
                                <Typography variant='p' component="p" sx={{mt:2, fontSize:{xs:12,sm:14,md:16}}}>{item.text}</Typography>
                            </Grid2>
                        )}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Container>
    </ThemeProvider>
  )
}

export default AboutUs