import { Box, Container, ThemeProvider, Typography, useTheme,Grid2, List, ListItem, ListItemAvatar,
    ListItemText, Link
 } from '@mui/material'

//icon 
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';

// import image
import translation from '../../assets/images/translation.jpeg'
import transcription from '../../assets/images/transcription.jpeg'
import imageGeneration from '../../assets/images/image_generation.jpeg'
import chatWithImage from '../../assets/images/chat_with_image.jpeg'
import chatWithVideo from '../../assets/images/chat_with_video.jpeg'
import chatWithAudio from '../../assets/images/chat_with_audio.jpeg'

import React from 'react'
import MyButton from '../../MyComponents/MyButton';


const Banner = () => {

    const theme = useTheme();
    const bannerText = [
        {
            id:1,
            text:<Typography variant='h4' fontWeight={200}>Empowering Innovation with <br/> <span style={{color:theme.palette.primary.dark,fontWeight:600,fontSize:50}}>castlespy</span> Precision<span style={{color:theme.palette.primary.dark,fontWeight:600}}>.</span></Typography>
        },
        {
            id:2,
            text:<Typography variant='h4' fontWeight={200}><span style={{color:theme.palette.secondary.dark,fontWeight:600,fontSize:50}}>castlespy</span> Solutions for a  Smarter Tomorrow<span style={{color:theme.palette.secondary.dark,fontWeight:600}}>.</span></Typography>
        },
        {
            id:3,
            text:<Typography variant='h4' fontWeight={200}>Unlocking the Power of <span style={{color:theme.palette.error.dark,fontWeight:600,fontSize:50}}>castlespy</span> Technology <span style={{color:theme.palette.error.dark,fontWeight:600}}>.</span></Typography>
        },
        {
            id:4,
            text:<Typography variant='h4' fontWeight={200}>Building a <span style={{color:theme.palette.warning.dark,fontWeight:600,fontSize:50}}>castlespy</span> Future Today<span style={{color:theme.palette.warning.dark,fontWeight:600}}>.</span></Typography>
        },
        {
            id:5,
            text:<Typography variant='h4' fontWeight={200}><span style={{color:theme.palette.info.dark,fontWeight:600,fontSize:50}}>castlespy</span> Excellence for Modern IT Challenges<span style={{color:theme.palette.info.dark,fontWeight:600}}>.</span></Typography>
        },
        {
            id:6,
            text:<Typography variant='h4' fontWeight={200}>Where  <span style={{color:theme.palette.error.dark,fontWeight:600,fontSize:50}}>castlespy</span> Ideas Transform into Reality<span style={{color:theme.palette.error.dark,fontWeight:600}}>.</span></Typography>
        }
    ]
    const productList = [
        {
            id:1,
            image:'',
            text:'Translation',
            img: <img src={translation} alt="translation" width="70%"/>,
        },
        {
            id:2,
            image:'',
            text:'Transcription',
            img:<img src={transcription} alt="transcription" width="70%"/>
        },
        {
            id:3,
            image:'',
            text:'Image Generation',
            img: <img src={imageGeneration} alt="imageGeneration" width="70%"/>,
        },
        {
            id:4,
            image:'',
            text:'Chat With Image',
            img: <img src={chatWithImage} alt="chatWithImage" width="70%"/>,
        },
        {
            id:5,
            image:'',
            text:'Chat With Video',
            img:<img src={chatWithVideo} alt="chatWithVideo" width="70%"/>,
        },
        {
            id:6,
            image:'',
            text:'Chat With Audio',
            img:<img src={chatWithAudio} alt="chatWithAudio" width="70%"/>,
        }
    ]
  return (
    <ThemeProvider theme={theme}>
        <Box maxWidth="xlg" sx={{textAlign:"center",mt:3,pt:3,pb:2,backgroundColor:theme.palette.primary.light}}>
            <Typography variant="p" >Get Exciting offer with our per year Subscription Plans. <a href="https://google.com/">Explore Plans</a></Typography>
        </Box>
      
        <Container sx={{mt:10,mb:20}}>

           {/* Banner */}
            <Grid2 container maxWidth="lg" sx={{mt:8,mb:8}}>
                    <Grid2 size={{xs:12, sm:12, md:8, lg:8}} sx={{p:4}}>
                        {bannerText[ Math.floor(Math.random() * 6)].text}
                    </Grid2>
                    <Grid2 size={{xs:12, sm:12, md:4, lg:4}} style={{ textAlign: 'right' }}>
                        <Container sx={{mt:{md:5,lg:5}}}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        {/* <Avatar> */}
                                            <SupportAgentIcon variant="contained" sx={{fontSize:50, mr:2}}/>
                                        {/* </Avatar> */}
                                    </ListItemAvatar>
                                    <ListItemText primary="Need a Help ?" secondary={<Link href="#">Contact Us</Link>}></ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        {/* <Avatar> */}
                                            <Diversity3RoundedIcon variant="contained" sx={{fontSize:50,mr:2}}/>
                                        {/* </Avatar> */}
                                    </ListItemAvatar>
                                    <ListItemText primary="Get Product & Services" secondary={<Link href="#"> Book a Demo</Link>}></ListItemText>
                                </ListItem>
                            </List>    
                        </Container>
                    </Grid2>
            </Grid2>

            {/* Products */}
            <Grid2 container spacing={2} sx={{mt:10,mb:10}}>
                {productList.map((item, key)=>
                    <Grid2 size={{xs:6,sm:3, md:3,lg:2}} sx={{textAlign:"center"}}>
                        {item.img}<br/>
                        <Typography variant="p" color={theme.palette.primary.dark} mt={1} sx={{fontSize:14}}>
                            {item.text}
                        </Typography>
                    </Grid2>
                )}
            </Grid2>

                <Box sx={{textAlign:"center"}}>
                    <a href="/contact">
                        <MyButton variant="contained" text="Book a Demo"></MyButton>
                    </a>
                </Box>
        </Container>
            
  
    </ThemeProvider>
  )
}

export default Banner