import React from 'react'
import {Container, ThemeProvider, useTheme,Box, Typography, Grid2, Link} from '@mui/material'

// Icons
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded';
import SchemaRoundedIcon from '@mui/icons-material/SchemaRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import InsertChartOutlinedRoundedIcon from '@mui/icons-material/InsertChartOutlinedRounded';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import FlareRoundedIcon from '@mui/icons-material/FlareRounded';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import LocalConvenienceStoreRoundedIcon from '@mui/icons-material/LocalConvenienceStoreRounded';



const Services = () => {

  const theme = useTheme()

  const data = [
    {
      id:1,
      image:<WebhookRoundedIcon sx={{fontSize:60, color:theme.palette.primary.main}}/>,
      heading:'IT Services and Product Development',
      text:'From ideation to deployment, we offer end-to-end product development services, including requirement gathering, architecture design, and scalable development solutions.',
      example:'Cloud-based inventory management systems or enterprise-level CRMs.',
      link:'/'
    },
    {
      id:2,
      image:<SchemaRoundedIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
      heading:'AI and Machine Learning Solutions',
      text:'Develop AI models tailored to your business, including predictive analytics, natural language processing (NLP), computer vision, and recommendation engines.',
      example: 'Chatbots, fraud detection systems, and customer behavior analytics.',
      link:'/'
    },
    {
      id:3,
      image:<AccountBalanceRoundedIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
      heading:'End-to-End Business Solutions',
      text:'Comprehensive solutions for automating workflows, optimizing processes, and improving decision-making across industries.',
      example:'ERP systems for supply chain management and financial planning tools.',
      link:'/'
    },
    {
      id:4,
      image:<InsertChartOutlinedRoundedIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
      heading:'Data Engineering and Analytics',
      text:'Enable data-driven decision-making by creating robust data pipelines, data lakes, and implementing analytics dashboards.',
      example:'ETL processes, Power BI dashboards, and real-time analytics systems.',
      link:'/'
    },
    {
      id:5,
      image:<AppRegistrationRoundedIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
      heading:'Web and Desktop Application Development',
      text:'Build modern, responsive websites and powerful desktop applications with intuitive UI/UX to enhance user engagement.',
      example:'Multi-platform e-commerce platforms or CMS tools.',
      link:'/'
    },
    {
      id:6,
      image:<PlaylistAddCheckCircleRoundedIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
      heading:'DevOps and Continuous Integration / Continuous Deployment (CI/CD)',
      text:'Automate development pipelines and streamline software deployment to ensure rapid delivery and high availability.',
      example:'AWS, Jenkins pipelines, Kubernetes clusters, and Docker containerization.',
      link:'/'
    },
    {
      id:7,
      image:<FlareRoundedIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
      heading:'UX/UI Design',
      text:'Create intuitive and visually appealing interfaces to enhance user engagement and experience.',
      example:'Designing mobile-friendly dashboards or interactive prototypes for SaaS products.',
      link:'/'
    },
    {
      id:8,
      image:<Diversity3RoundedIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
      heading:'IT Consulting and Strategy',
      text:' Offer strategic guidance on leveraging technology to meet business objectives and stay competitive.',
      example:' IT audits, tech-stack recommendations, and digital transformation roadmaps.',
      link:'/'
    },
    {
      id:9,
      image:<LocalConvenienceStoreRoundedIcon sx={{fontSize:60,color:theme.palette.primary.main}}/>,
      heading:'Digital Marketing and SEO Services',
      text:'Grow your online presence with SEO, pay-per-click (PPC), social media strategies, and content marketing.',
      example:'Optimized landing pages, Google Ads campaigns, and analytics-driven insights.',
      link:'/'
    }
  ]
  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{mt:15,mb:15}}>
        <Container sx={{textAlign:"center"}}>
          <Typography variant='h3'>Services</Typography>
          <Grid2 container sx={{mt:10}}>
            {data.map((item, key)=>
              <Grid2 size={{xs:6,sm:6,md:4,lg:4}} sx={{p:4}} key={item.id}>
                <Typography variant='h1' component="p" >{item.image}</Typography>
                <Typography variant='h6' component="h6">{item.heading}</Typography>
                <Typography variant='p' component="p" sx={{fontSize:12,mt:2,mb:2}}>{item.text}</Typography>
                <Link href={item.link}>Learn More</Link>
              </Grid2>
            )}
            </Grid2>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Services